const cognitoSettings = {
  REGION: "us-east-2",
  USER_POOL_ID: "us-east-2_U9AXQtVqQ",
  APP_CLIENT_ID: "7j142lc6509029uopi6mlgiq47",
  IDENTITY_POOL_ID: "us-east-2:67ae1ad5-21af-406a-929e-0d04e32e9067",
}
 // IDENTITY_POOL_ID this is not correct...: 64f454da-cb9c-4c91-8b9c-c667a05dbcb9
const dev = {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://dev.api.pix.halfstars.com"
  },
  s3: {
    BUCKET: "pixhalfstarsapp-dev",
    REGION: "us-east-2",
  }
}

const prod =  {
  cognito: cognitoSettings,
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://api.pix.halfstars.com",
  },
  s3: {
    BUCKET: "pixhalfstarsapp",
    REGION: "us-east-2",
  }
}

export const APINAME="photos"

export default process.env.NODE_ENV === "development" ? dev : prod;
